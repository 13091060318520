// esta es la pagina de gracias de contacto
import React from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"
export default function Gracias() {
  return (
    <>
      <Helmet>
        <title>Mensaje de gracias de contacto</title>
        <meta
          name="description"
          content="este es el gracias de los formularios de contactos"
        ></meta>
      </Helmet>
      <Layout>
        <h1>MUCHAS GRACIAS</h1>
      </Layout>
    </>
  )
}
